import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface IFormWrapProps {
    parentWrapperClass?: string;
}

export const FormWrap: React.FC<IFormWrapProps> = ({
    children,
    parentWrapperClass = '',
}) => (
    <div className={parentWrapperClass ? classes(styles.wrap, parentWrapperClass) : styles.wrap}>
        {children}
    </div>
);
