import React from 'react';
import { LogIn } from '@silkpwa/module/react-component/log-in';
import { GoogleRecaptchaWidget } from '@silkpwa/module/google-recaptcha';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { connectCMSContent, IContentCMSContent } from '@silkpwa/module/react-component/connect-cms-content';
import { Container, containerHasContent } from 'ui/component/container';
import { FormInputPropertyCode } from 'ui/component/portal/form-input-property-code';
import { PortalData } from 'ui/component/portal/portal-data/portal-data';
import { usePortalInfo } from '@silkpwa/module/portal';
import { useDesignConfig } from '@silkpwa/module/design-config';
import { Errors } from './errors';
import { LogInWrap } from './log-in-wrap';
import { FormWrap } from './form-wrap';
import { TabBar, Tab } from './tab-bar';
import { Fields } from './fields';
import { FormInput } from './form-input';
import { PasswordInput } from './password-input';
import { SignInButton } from './sign-in-button';
import { ForgotPassword } from './forgot-password';
import { SignUp } from './sign-up';
import { MarketingTop } from './marketing';
import { Notice } from './notice';

interface LogInFormProps extends IContentCMSContent {
    hideTab?: boolean;
    parentLoginWrapClass?: string;
    parentFormWrapClass?: string;
    redirectAfterLoggedIn?: boolean;
    parentClass?: string;
}

const LogInForm = ({
    getContent,
    hideTab = false,
    parentLoginWrapClass,
    parentFormWrapClass,
    redirectAfterLoggedIn = true,
    parentClass = '',
}: React.PropsWithChildren<LogInFormProps>) => {
    const t = usePhraseTranslater();
    const designConfig = useDesignConfig();
    const loginPageHeading = `${t('NEW TO')} ${designConfig.pageTitle}?`;
    const {
        isPortal,
        isForceLogin,
        isCustomerLoggedIn,
        portalCode,
        portal,
    } = usePortalInfo();

    const pcNoticeContainer = getContent('pc-notice-login');
    const isPcNoticeBlock = containerHasContent(pcNoticeContainer);
    const propertyCodeNotice: JSX.Element|string = isPcNoticeBlock
        ? (<Container id="pc-notice-login" />)
        : t('If you wish to login for a Portal customer, please include a property code. Otherwise, leave it blank.');

    return (
        <LogIn
            redirectAfterLoggedIn={redirectAfterLoggedIn}
            isPortal={isPortal}
            isForceLogin={isForceLogin}
            portalCode={portalCode}
        >
            {logIn => (
                <LogInWrap parentWrapperClass={parentLoginWrapClass}>
                    {isPortal ? (
                        <PortalData
                            area="aboveLoginMessage"
                            data={portal}
                            isCustomerLoggedIn={isCustomerLoggedIn}
                        />
                    ) : '' }
                    <form onSubmit={logIn.logIn}>
                        <FormWrap parentWrapperClass={parentFormWrapClass}>
                            <MarketingTop />
                            {!hideTab
                                 ? (
                                     <TabBar>
                                         <Tab href="/customer/account/login" selected>
                                             {t('WELCOME BACK')}
                                         </Tab>
                                         <Tab href="/customer/account/create">{loginPageHeading.toUpperCase()}</Tab>
                                     </TabBar>
                                )
                            : '' }
                            <Fields>
                                <FormInput
                                    onChange={logIn.setUsername}
                                    placeholder={t('Email')}
                                    type="text"
                                    id="loginIdentity"
                                    isRequired="true"
                                    value={logIn.username}
                                />
                                {!isCustomerLoggedIn && isPortal && (logIn.propertyCodeVisible || portal) ? (
                                    <div className="propertyCode">
                                        <FormInputPropertyCode
                                            onChange={logIn.setPropertyCode}
                                            placeholder={t('Property Code')}
                                            type="text"
                                            isRequired={logIn.isPropertyCodeRequired}
                                            value={logIn.propertyCode}
                                        />
                                        <Notice
                                            isVisible={!logIn.isPropertyCodeRequired}
                                            message={propertyCodeNotice}
                                        />
                                    </div>
                                ) : '' }
                                <PasswordInput
                                    placeholder={t('Password')}
                                    isRequired="true"
                                    id="pwdIdentity"
                                    onChange={logIn.setPassword}
                                    onToggle={logIn.togglePasswordVisible}
                                    value={logIn.password}
                                    visible={logIn.passwordVisible}
                                />
                                <GoogleRecaptchaWidget form="login" />
                                <ForgotPassword href="/customer/account/forgotpassword">
                                    {t('FORGOT YOUR PASSWORD')}
                                </ForgotPassword>
                                <Errors errors={logIn.errors} parentClass={parentClass} />
                                <SignInButton text={t('SIGN IN')} processing={logIn.isLoggingIn} />
                                <SignUp
                                    questionText={t("DON'T HAVE AN ACCOUNT YET?")}
                                    linkText={t('SIGN UP')}
                                    href="/customer/account/create"
                                />
                            </Fields>
                        </FormWrap>
                    </form>
                    {isPortal ? (
                        <PortalData
                            area="belowLoginMessage"
                            data={portal}
                            isCustomerLoggedIn={isCustomerLoggedIn}
                        />
                    ) : '' }
                </LogInWrap>
            )}
        </LogIn>
    );
};

const LogInFormWrap = connectCMSContent(LogInForm);

export { LogInFormWrap as LogInForm };
